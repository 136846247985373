$fullhd: 1024px;

@import '~bulma';

$steps-default-color: #c5dbe8;
$steps-completed-color: #4aace8;
$steps-active-color: #4aace8;
$steps-marker-size: 1.5;

@import 'bulma-steps-component/bulma-steps';

.steps.is-small p {
	font-size: 1rem;
	font-weight: bold;
}
