.main {
	margin-top: 30px;
	@media screen and (max-width: 768px) {
		padding: 0 10px;
	}

	.page-title {
		margin: 40px auto 40px;
	}
}
