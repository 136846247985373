header {
	padding: 10px;
	background: #fafafa;

	.level-right {
		a {
			color: #363636;
		}
	}

	.level-left {
		img {
			max-width: 150px;
		}
	}

	@media screen and (max-width: 768px) {
		.level-left + .level-right {
			margin-top: 0.5rem;
		}
	}
}
