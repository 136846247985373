@import url('https://fonts.googleapis.com/css?family=Assistant:300,400,700');

$link-color: #4aace8;

html {
	font-size: 14px;
	font-weight: 400;
}

* {
	font-size: 1rem;
	color: #1a1717;
	font-family: 'Assistant', sans-serif;
}

.link,
a {
	cursor: pointer;
	color: $link-color;
	&:hover {
		color: #0899f3;
	}
}

h1 {
	font-size: 2rem;
	margin-bottom: 10px;
}

h2 {
	font-size: 1.7rem;
}

h3 {
	font-size: 1.5rem;
}
