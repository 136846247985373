.form {
	//
	.help {
		font-size: 1rem;
	}

	.fas {
		color: #cacaca;
	}

	.button {
		font-weight: bold;
	}
}
